import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import md5 from "md5";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import { getTypeOfPath, showPathEntries } from "../utils/fileSystem";
import { FILE, FOLDER, LISTA } from "../utils/constants";

import Icon from "./GridIcon/GridIcon";
import Add from "./DocAdd";
import useGetFileSystem from "../hooks/fetchers/useGetFileSystem";
import useCreateDocument from "../hooks/fetchers/useCreateDocument";
import useDeleteDocument from "../hooks/fetchers/useDeleteDocument";
import useCreateFile from "../hooks/fetchers/useCreateFile";
import useUpdateDocument from "../hooks/fetchers/useUpdateDocument";

function Grid() {
  const { data, isLoading } = useGetFileSystem();
  const { mutate: createDocument } = useCreateDocument();
  const { mutate: createFile, isLoading: isUploading } = useCreateFile();
  const { mutate: deleteDocument } = useDeleteDocument();
  const { mutate: updateDocument } = useUpdateDocument();
  const location = useLocation();
  const navigate = useNavigate();
  const [isAddEditModalOpen, toggleAddEditModal] = useState(false);
  const [isEdit, toggleEdit] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const entries = useMemo(
    () => showPathEntries(location.pathname, isLoading ? {} : data),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading]
  );

  useEffect(() => {
    if (
      !isLoading &&
      !Object.keys(data).includes(md5(location.pathname + FOLDER)) &&
      !Object.keys(data).includes(md5(location.pathname + LISTA))
    ) {
      navigate("/documents");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function saveEntry(value, edit) {
    if (value.type === FILE && !edit) {
      createFile({
        data,
        newEntry: {
          ...value,
          parentID: md5(
            decodeURI(location.pathname) +
              getTypeOfPath(location.pathname, isLoading ? {} : data)
          ),
          parentPath: location.pathname,
        },
      });
    } else {
      if (edit) {
        updateDocument({
          data,
          newEntry: {
            ...value,
            parentID: md5(
              decodeURI(location.pathname) +
                getTypeOfPath(location.pathname, isLoading ? {} : data)
            ),
            parentPath: location.pathname,
          },
        });
      } else {
        createDocument({
          data,
          newEntry: {
            ...value,
            parentID: md5(
              decodeURI(location.pathname) +
                getTypeOfPath(location.pathname, isLoading ? {} : data)
            ),
            parentPath: location.pathname,
          },
        });
      }
    }
  }

  function deleteEntry(entry) {
    deleteDocument({
      fileSystem: data,
      entryID: md5(entry.path + entry.documentType),
    });
  }

  return (
    <Container>
      {entries.map((entry, _) => (
        <Icon
          isEdit={isEdit}
          toggleEdit={toggleEdit}
          openAddEditModal={() => toggleAddEditModal(true)}
          setSelectedDoc={setSelectedDoc}
          entry={entry}
          index={_}
          key={`${entry.path}_${entry.documentType}`}
          deleteFn={() => deleteEntry(entry)}
        />
      ))}
      {isUploading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Add
          isEdit={isEdit}
          toggleEdit={toggleEdit}
          open={isAddEditModalOpen}
          closeFn={() => toggleAddEditModal(false)}
          openFn={() => toggleAddEditModal(true)}
          selectedDoc={selectedDoc}
          setSelectedDoc={setSelectedDoc}
          saveEntry={(value, edit) => saveEntry(value, edit)}
          docType={getTypeOfPath(location.pathname, isLoading ? {} : data)}
        />
      )}
    </Container>
  );
}

export default Grid;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 40px 0;
`;
