import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../components/Sidebar";
import styled from "styled-components";

import Navigation from "../components/Navigation/Navigation";
import SearchBar from "../components/SearchBar";
import Grid from "../components/Grid";

import "../styles/documentsPage.css";

const DocumentsPage = () => {
  return (
    <Sidebar title="Sadržaj">
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Toolbar />
        <Container>
          <TopBar>
            <Navigation />
            <SearchBar />
          </TopBar>
          <Grid />
        </Container>
      </Box>
    </Sidebar>
  );
};

const Container = styled.div`
  padding: 41px;
  transition: margin-left 250ms ease-in;
  @media screen and (max-width: 768px) {
    margin-left: 0px;
    padding: 55px 15px 15px 15px;
  }
`;

const TopBar = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export default DocumentsPage;
