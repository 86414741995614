import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { Formik, Field } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import withModal from "../DocModal";
import useGetFileSystem from "../../hooks/fetchers/useGetFileSystem";

import { FILE, FOLDER, LISTA, LINK } from "../../utils/constants";
import { getTypeOfPath } from "../../utils/fileSystem";

import { Container, Error, Top, Toggle, Form } from "./styles";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import useGetPackages from "../../hooks/fetchers/useGetPackages";

function FileInfo(_props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState([]);
  const { data: fileSystem, isLoading } = useGetFileSystem();
  const { data: packages } = useGetPackages();
  const location = useLocation();
  const [type, handleType] = useState(FOLDER);
  const [pathType, setPathType] = useState(FOLDER);

  useEffect(() => {
    if (_props.isEdit) {
      handleType(_props.selectedDoc.documentType);

      if (_props.selectedDoc.subscriptionIds) {
        setSelectedPackage(_props.selectedDoc.subscriptionIds ?? []);
      } else {
        setSelectedPackage([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const docType = getTypeOfPath(location.pathname, fileSystem);
      setPathType(docType);

      if (docType === LISTA) {
        handleType(FILE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const documentType = useMemo(() => {
    switch (_props.isEdit && _props.selectedDoc.documentType) {
      case FILE:
        return "File";
      case LISTA:
        return "Lista";
      case FOLDER:
        return "Folder";
      case LINK:
        return "Link";
      default:
        return "Folder";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function selectPackage(selected) {
    let selectedValues = selected;

    if (
      selectedValues.includes("none") &&
      selectedValues[selectedValues.length - 1] !== "none"
    ) {
      selectedValues.shift();
    } else if (selectedValues[selectedValues.length - 1] === "none") {
      selectedValues = ["none"];
    }

    setSelectedPackage(selectedValues);
  }

  return isLoading ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Container>
      {_props.isEdit ? (
        <p style={{ textAlign: "center" }}>{documentType}</p>
      ) : (
        <Top>
          <Toggle.Container>
            {pathType === LISTA ? (
              <Toggle.Option
                className={type === FILE ? "selected" : ""}
                onClick={() => handleType(FILE)}
              >
                Dokument
              </Toggle.Option>
            ) : null}
            {pathType === LISTA ? null : (
              <Toggle.Option
                className={type === LISTA ? "selected" : ""}
                onClick={() => handleType(LISTA)}
              >
                Lista
              </Toggle.Option>
            )}
            {pathType === LISTA ? null : (
              <Toggle.Option
                className={type === FOLDER ? "selected" : ""}
                onClick={() => handleType(FOLDER)}
              >
                Kategorija
              </Toggle.Option>
            )}
            {pathType === LISTA ? null : (
              <Toggle.Option
                className={type === LINK ? "selected" : ""}
                onClick={() => handleType(LINK)}
              >
                Link
              </Toggle.Option>
            )}
          </Toggle.Container>
        </Top>
      )}

      <Formik
        initialValues={{
          type: "file",
          name: _props.isEdit ? _props.selectedDoc.name : "",
          link: _props.isEdit ? _props.selectedDoc.link : "",
          file: _props.isEdit ? _props.selectedDoc.file : "",
        }}
        validate={(values) => {
          let errors = {};
          const urlRegEx = new RegExp(
            "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})"
          );

          if (!values.name) {
            errors.name = "Obavezno polje";
          } else if (type === LINK && !urlRegEx.test(values.link)) {
            errors.link = "Obavezno polje";
          } else if (!_props.isEdit && type === FILE && !values.file) {
            errors.file = "Obavezno polje";
          } else if (type === FOLDER && selectedPackage.length === 0) {
            errors.package = "Obavezno polje";
          }

          return errors;
        }}
        onSubmit={(values, actions) => {
          if (type !== LINK) {
            delete values.link;
          }

          if (type === FOLDER) {
            values.package =
              selectedPackage === "none" ? null : selectedPackage;
          }

          _props.addEntry(
            {
              ...values,
              file: selectedFile,
              type,
              id: _props.isEdit ? _props.selectedDoc.id : null,
            },
            _props.isEdit
          );
          _props.closeFn();
        }}
      >
        {(props) => {
          return (
            <Form.Container
              style={
                _props.isEdit
                  ? {
                      marginTop: "1rem",
                    }
                  : null
              }
            >
              <div className="formField">
                <Field
                  placeholder="Ime"
                  onChange={props.handleChange}
                  name="name"
                  className="field"
                  value={props.values.name}
                  autoComplete="off"
                />
                {props.errors.name && props.touched.name ? (
                  <Error>{props.errors.name}</Error>
                ) : (
                  ""
                )}
              </div>

              {type === FOLDER ? (
                <FormControl className="lock-modal-select">
                  <InputLabel id="package-select-label">Paket</InputLabel>
                  <Select
                    labelId="package-select-label"
                    value={selectedPackage}
                    label="Paket"
                    onChange={(e) => selectPackage(e.target.value)}
                    className="lock-modal-select lock-modal-select--new"
                    style={{ zIndex: 2001 }}
                    multiple
                  >
                    <MenuItem value="none" key="none">
                      Bez Paketa
                    </MenuItem>
                    {packages?.result?.map((packageItem) => (
                      <MenuItem key={packageItem.id} value={packageItem.id}>
                        {packageItem.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}

              {type === FILE && !_props.isEdit ? (
                <div className="formField">
                  <Field
                    type="file"
                    placeholder="File"
                    onChange={(e) => {
                      props.setFieldValue("file", e.target.files[0].name);
                      setSelectedFile(e.target.files[0]);
                    }}
                    name="file"
                    className="field"
                    value={undefined}
                    accept=".html, .pdf"
                  />
                  {props.errors.file && props.touched.file ? (
                    <Error>{props.errors.file}</Error>
                  ) : (
                    ""
                  )}
                </div>
              ) : null}

              {type === LINK ? (
                <div className="formField">
                  <Field
                    placeholder="Link"
                    onChange={props.handleChange}
                    name="link"
                    className="field"
                    value={props.values.link}
                    autoComplete="off"
                  />
                  {props.errors.link && props.touched.link ? (
                    <Error>{props.errors.link}</Error>
                  ) : (
                    ""
                  )}
                </div>
              ) : null}

              {/* NE BRISATI, MOZDA CE TREBATI  */}
              {/* {type === FOLDER ? (
                <FormControlLabel
                  name="isLocked"
                  control={<Checkbox defaultChecked={props.values.isLocked} />}
                  label="Zaključan"
                  onChange={props.handleChange}
                  value={true}
                  style={{ marginBottom: "1rem" }}
                />
              ) : null} */}

              <Form.Submit type="submit" onClick={props.handleSubmit}>
                {_props.isEdit ? "Izmeni" : "Kreiraj"}
              </Form.Submit>
            </Form.Container>
          );
        }}
      </Formik>
    </Container>
  );
}

export default withModal(FileInfo)({
  style: {
    position: "absolute",
    top: "25%",
    left: "40%",
    transform: "translate(-50%, -50%)",
  },
});
