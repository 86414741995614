import React, { useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import ChatElement from "./ChatElement";
import useGetChats from "../../hooks/fetchers/useGetChats";

export const ChatBox = ({
  chatId,
  searchedChat,
  chatType,
  openChat,
  setData,
}) => {
  const { data, isLoading } = useGetChats(chatType);

  useEffect(() => {
    setData(data);
  }, [data]);

  return isLoading ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    searchedChat &&
      Array.isArray(searchedChat) &&
      searchedChat.length > 0 &&
      searchedChat.map((x, index) => (
        <ChatElement
          key={index}
          chatId={x?.id}
          email={x?.email}
          firstName={x?.firstName}
          lastName={x?.lastName}
          subscriptionName={x?.subscriptionName}
          isRead={x?.isRead}
          selectedChatId={chatId}
          callback={(prop) => openChat(prop)}
          isArchive={chatType === "archive" ? true : false}
        />
      ))
  );
};
