import { useQuery } from "react-query";
import { format } from "date-fns";
import QueryKeys from "./queryKeys";
import { apiService } from "../../utils/api";

const { GET_ACCOUNTS } = QueryKeys;

async function getAccounts() {
  const response = await apiService("/accounts", {
    method: "GET",
    credentials: "include",
  });
  return response.json();
}

export default function useGetAccounts(onSuccess, onError) {
  return useQuery(GET_ACCOUNTS, getAccounts, {
    onSuccess,
    onError,
    select: (data) =>
      data.result.map((account) => ({
        ...account,
        typeOfAccount: account.roleName,
        package: account.subscriptionName,
        dateOfExpiration: format(
          new Date(account.subscriptionExpiryDate),
          "dd/MM/yyyy"
        ),
      })),
  });
}
