import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import useMoveDocument from "../../hooks/fetchers/useMoveDocument";
import useCopyDocument from "../../hooks/fetchers/useCopyDocument";
import useGetFileSystem from "../../hooks/fetchers/useGetFileSystem";
import withModal from "../DocModal";

import "../../styles/lockModal.css";
import { FILE, FOLDER, LISTA } from "../../utils/constants";
import { decodeURL } from "../../utils";

function FileMoveCopy({ mode, entry }) {
  const { data: fileSystem, isLoading, isFetching } = useGetFileSystem();
  const { mutate: moveDocument } = useMoveDocument();
  const { mutate: copyDocument } = useCopyDocument();
  const [selectedPath, setSelectedPath] = useState(null);

  const filterDocuments = () => {
    const documents = [];

    for (const key in fileSystem) {
      const obj = fileSystem[key];
      obj.hashPath = key;
      documents.push(obj);
    }

    const filteredDocs = documents.filter((doc) => {
      if (entry.documentType === FILE) {
        return doc.documentType === LISTA;
      } else {
        return doc.documentType === FOLDER;
      }
    });

    const result = filteredDocs.map((doc) => ({
      id: doc.id,
      label: decodeURL(doc.path),
    }));

    return result;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const paths = useMemo(() => filterDocuments(), []);

  if (isLoading || isFetching) {
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      <CircularProgress />
    </Box>;
  }

  function onSubmit() {
    if (mode === "copy") {
      copyDocument({ documentId: entry.id, newParentId: selectedPath });
    } else {
      moveDocument({ documentId: entry.id, newParentId: selectedPath });
    }
  }

  return (
    <Box className="lock-modal">
      <FormControl className="lock-modal-select">
        <Autocomplete
          disablePortal
          options={paths}
          renderInput={(params) => <TextField {...params} label="Lokacije" />}
          onChange={(_, value) => setSelectedPath(value?.id)}
        />
      </FormControl>
      <Button
        className="lock-modal-button"
        variant="contained"
        onClick={onSubmit}
      >
        {mode === "copy" ? "Kopiraj" : "Premesti"}
      </Button>
    </Box>
  );
}

export default withModal(FileMoveCopy)({
  style: {
    position: "absolute",
    zIndex: 1201,
  },
});
