import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import { useLoginForm } from "../hooks/forms/useLoginForm";

import "../styles/login.css";
import { Checkbox } from "@mui/material";

const LoginPage = () => {
  const [formik, isLoading] = useLoginForm();

  return (
    <div className="rootRegister">
      <form onSubmit={formik.handleSubmit} className="rootRegister__buttonBox">
        <h2>Prijavite se</h2>
        <div className="login-label-wrapper space-top">
          <label>E-pošta</label>
        </div>
        <TextField
          label="Epošta"
          variant="outlined"
          value={formik.values.email}
          className="input space-top"
          type="text"
          name="email"
          id="email"
          error={formik.touched.email && formik.errors.email}
          helperText={formik.touched.email && formik.errors.email ? formik.errors.email : null}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <div className="login-label-wrapper space-top">
          <label>Lozinka</label>
          <a className="forgot-password-link" href='reset-password' alt="">Zaboravili ste lozinku?</a>
        </div>
        <TextField
          label="Lozinka"
          variant="outlined"
          value={formik.values.password}
          className="input space-top"
          type="password"
          name="password"
          id="password"
          error={formik.touched.password && formik.errors.password}
          helperText={formik.touched.password && formik.errors.password ? formik.errors.password : null}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <div className="login-label-wrapper space-top">
          <div>
            <Checkbox />
            <label>Zapamti me</label>
          </div>
          <LoadingButton loading={isLoading} type="submit" className="login-btn" variant="contained">
            Prijavite se
          </LoadingButton>
        </div>
      </form>
    </div>
  );
}

export default LoginPage