import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Keys from "./queryKeys";
import { apiService } from "../../utils/api";

const { GET_PACKAGES } = Keys;

function createPackage(data) {
  const body = {
    name: data.name,
    description: data.description,
  };

  return apiService("/subscriptions", {
    method: "POST",
    body: JSON.stringify(body),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default function useCreatePackage() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(createPackage, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_PACKAGES);
      navigate("/packages");
      navigate(0);
    },
  });
}
