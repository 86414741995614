import { useMutation, useQueryClient } from 'react-query';
import { apiService } from '../../utils/api';
import Keys from "./queryKeys";

const { GET_CHATS } = Keys;

async function archiveChat(archiveBody) {
  return apiService("/chats/archive", {
    method: "POST",
    body: JSON.stringify(archiveBody),
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function useArchiveChat() {
  const queryClient = useQueryClient();

  return useMutation(archiveChat, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_CHATS, "active"]);
      queryClient.invalidateQueries([GET_CHATS, "archive"]);
      window.location.href = `${process.env.REACT_APP_SITE_URL}/chat`
    },
  });
}