export const decodeURL = (url) => {
  let result = url;

  if (result.includes("%20")) {
    result = result.replace(/%20/g, " ");
  }

  if (result.includes("%C5%A1")) {
    result = result.replace(/%C5%A1/g, "š");
  }

  if (result.includes("%C5%A0")) {
    result = result.replace(/%C5%A0/g, "Š");
  }

  if (result.includes("%C4%90")) {
    result = result.replace(/%C4%90/g, "Đ");
  }

  if (result.includes("%C4%91")) {
    result = result.replace(/%C4%91/g, "đ");
  }

  if (result.includes("%C4%8C")) {
    result = result.replace(/%C4%8C/g, "Č");
  }

  if (result.includes("%C4%8D")) {
    result = result.replace(/%C4%8D/g, "č");
  }

  if (result.includes("%C4%86")) {
    result = result.replace(/%C4%86/g, "Ć");
  }

  if (result.includes("%C4%87")) {
    result = result.replace(/%C4%87/g, "ć");
  }

  if (result.includes("%C5%BD")) {
    result = result.replace(/%C5%BD/g, "Ž");
  }

  if (result.includes("%C5%BE")) {
    result = result.replace(/%C5%BE/g, "ž");
  }

  if (result.includes("%22")) {
    result = result.replaceAll("%22", '"');
  }

  return result;
};

export const truncateString = (str, num) => {
  return str.length > num ? str.slice(0, num) + "..." : str;
};
