import styled from "styled-components";
import USER_IMG from "../../icons/user_img.svg";
import { memo, useEffect, useState } from "react";
import { useArchiveChat } from "../../hooks/fetchers/useArchiveChat";
import { Box, CircularProgress } from "@material-ui/core";

const ChatElement = ({ chatId, email, firstName, lastName, subscriptionName, isRead, selectedChatId, callback, isArchive }) => {
  const [name, setName] = useState()
  const { mutate, isLoading } = useArchiveChat();

  useEffect(() => {
    if ((firstName && firstName !== "") || (lastName && lastName !== "")) {
      setName(`${firstName ? firstName : ""} ${lastName ? lastName : ""}`);
    } else if (email && email !== "") {
      setName(email);
    }
  });

  const archive = (e) => {
    e.stopPropagation();
    mutate({chatIds: [chatId]});
  }
  return (
    isLoading ? (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </Box>
    ) : (
      <Container isOpened={chatId && selectedChatId && selectedChatId === chatId ? true : false} isRead={isRead} onClick={() => callback(chatId)}>
        <img src={USER_IMG} alt="User" width={30} />
        <TextContainer> {name} | {subscriptionName} </TextContainer>
        {!isArchive && <ArchiveButton onClick={archive}>arhiviraj</ArchiveButton>}
      </Container>
    )
  )
}

export default memo(ChatElement);

const Container = styled.button`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
  background: ${props => props.isRead || props.isOpened ? "#ffffff" : "#adadad"};
  padding: 0 0.5rem;
  border: ${props => props.isOpened ? "3px black solid" : "none"};

  &:hover {
    background: #F5F5F5;
    cursor: pointer;
  }
`;

const TextContainer = styled.p`
  margin: 0;
  padding: 0 0.3rem;
`;

const ArchiveButton = styled.button`
  margin-left: auto;
  font-size: x-small;
  cursor: pointer;
`;