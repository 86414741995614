import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { createBrowserHistory } from 'history'

const drawerWidth = 240;

const PATH_NAME = {
  "/packages": "PACKAGES",
  "/accounts": "ACCOUNTS",
  "/documents": "DOCUMENTS",
}

function Sidebar({ children, title }) {
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = React.useState();
  const history = createBrowserHistory();

  React.useEffect(() => {
    if (history?.location?.pathname) {
      setSelectedMenuItem(PATH_NAME[history.location.pathname]);
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("at");
    localStorage.removeItem("rt");
    navigate("/");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Divider />
        <List>
          <ListItem key={1} disablePadding>
            <ListItemButton onClick={() => navigate("/chat")}>
              <ListItemText primary={"Čet"} />
            </ListItemButton>
          </ListItem>
          { selectedMenuItem === "ACCOUNTS" ?
            <ListItem key={2} disablePadding style={{background: "#d6d6d6"}}>
            <ListItemButton onClick={() => navigate("/accounts")}>
              <ListItemText primary={"Nalozi"} />
            </ListItemButton>
            </ListItem> :
            <ListItem key={2} disablePadding>
            <ListItemButton onClick={() => navigate("/accounts")}>
              <ListItemText primary={"Nalozi"} />
            </ListItemButton>
            </ListItem>
          }
          { selectedMenuItem === "DOCUMENTS" ?
            <ListItem key={3} disablePadding style={{background: "#d6d6d6"}}>
              <ListItemButton onClick={() => navigate("/documents")}>
                <ListItemText primary={"Sadržaj"} />
              </ListItemButton>
            </ListItem> :
            <ListItem key={3} disablePadding>
              <ListItemButton onClick={() => navigate("/documents")}>
                <ListItemText primary={"Sadržaj"} />
              </ListItemButton>
            </ListItem>
          }
          { selectedMenuItem === "PACKAGES" ?
            <ListItem key={4} disablePadding style={{background: "#d6d6d6"}}>
              <ListItemButton onClick={() => navigate("/packages")}>
                <ListItemText primary={"Paketi"} />
              </ListItemButton>
            </ListItem> :
            <ListItem key={4} disablePadding>
              <ListItemButton onClick={() => navigate("/packages")}>
                <ListItemText primary={"Paketi"} />
              </ListItemButton>
            </ListItem>
          }
          <ListItem key={5} divider></ListItem>
          <ListItem key={6} disablePadding divider>
            <ListItemButton onClick={() => logout("")}>
              <ListItemText primary={"Odjavi me"} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      {children}
    </Box>
  );
}

export default Sidebar;
