import React, { Fragment, memo, useMemo } from "react";
import styled from "styled-components";
import CreateNew from "./DocCreateNew/DocCreateNew";
import { FOLDER, LISTA } from "../utils/constants";

function Add(props) {
  const title = useMemo(() => {
    let result = "Kreiraj Novu Kategoriju";

    if (props.docType === FOLDER && props.isEdit) {
      result = "Izmeni Kategoriju";
    } else if (props.docType === LISTA && !props.isEdit) {
      result = "Kreiraj Novi Dokument";
    } else if (props.docType === LISTA && props.isEdit) {
      result = "Izmeni Dokument";
    }

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.docType, props.isEdit]);

  function closeModal() {
    props.closeFn();
    props.toggleEdit(false);
    props.setSelectedDoc(null);
  }

  return (
    <Fragment>
      <Container onClick={() => props.openFn()}>+</Container>
      {props.open ? (
        <CreateNew
          title={title}
          closeFn={() => closeModal()}
          addEntry={(value, edit) => props.saveEntry(value, edit)}
          isEdit={props.isEdit}
          toggleEdit={props.toggleEdit}
          selectedDoc={props.selectedDoc}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
}

export default memo(Add);

const Container = styled.div`
  height: 109px;
  width: 96px;
  border: 3px dashed #dee0e4;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  align-items: center;
  font-size: 30px;
  color: #dee0e4;
  margin: -6px 21px;
  cursor: copy;
`;
