import { produce } from "immer";
import {
  CLEAN_SELECTED_ITEM,
  HIDE_MODAL,
  REMOVE_EDIT_MODE,
  SET_EDIT_MODE,
  SET_SELECTED_ITEM,
  SHOW_MODAL,
} from "./accountsActionTypes";

export const initialState = {
  selectedItem: null,
  editMode: false,
  isModalShown: false,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case SET_SELECTED_ITEM:
      draft.selectedItem = action.payload;
      break;
    case CLEAN_SELECTED_ITEM:
      draft.selectedItem = null;
      break;
    case SHOW_MODAL:
      draft.isModalShown = true;
      break;
    case HIDE_MODAL:
      draft.isModalShown = false;
      break;
    case SET_EDIT_MODE:
      draft.editMode = true;
      break;
    case REMOVE_EDIT_MODE:
      draft.editMode = false;
      break;
    default:
      break;
  }

  return draft;
});
