import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Keys from "./queryKeys";
import { apiService } from "../../utils/api";

const { GET_FILE_SYSTEM } = Keys;

function copyDocument({ documentId, newParentId }) {
  const body = {
    documentId,
    newParentId,
  };

  return apiService("/documents/duplicate", {
    method: "POST",
    body: JSON.stringify(body),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default function useCopyDocument() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(copyDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_FILE_SYSTEM);
      navigate(0);
    },
  });
}
