import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Keys from "./queryKeys";
import { apiService } from "../../utils/api";

const { GET_ACCOUNTS } = Keys;

function deleteAccounts(ids) {
  const body = {
    userIds: ids,
  };

  return apiService("/accounts", {
    method: "DELETE",
    body: JSON.stringify(body),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default function useDeleteAccount() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(deleteAccounts, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_ACCOUNTS);
      navigate(0);
    },
  });
}
