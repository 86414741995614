import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Keys from "./queryKeys";
import { apiService } from "../../utils/api";

const { GET_PACKAGES } = Keys;

function deletePackages(ids) {
  const body = {
    packageIds: ids,
  };

  return apiService("/subscriptions", {
    method: "DELETE",
    body: JSON.stringify(body),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default function useDeletePackage() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(deletePackages, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_PACKAGES);
      navigate(0);
    },
  });
}
