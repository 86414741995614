import { Divider, Toolbar, Typography } from "@material-ui/core";
import styled, { css } from "styled-components";
import { useEffect, useState } from "react";
import { ChatBox } from "../components/Chat/ChatBox";
import { MessagesBox } from "../components/Chat/MessagesBox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../utils/screenSize";

const ChatPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [currentChatId, setCurrentChatId] = useState();
  const [currentChatOwner, setCurrentChatOwner] = useState();
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState((width >= 991) ? false : true);
  const [search, setSearch] = useState("");
  const [searchedChat, setSearchedChat] = useState();
  const [isArchive, setIsArchive] = useState(false);

  useEffect(() => {
    if (width >= 500) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [width]);

  useEffect(() => {
    updateSearchedChat(search);
  }, [data]);

  const updateSearchedChat = (filter) => {
    if (!isEmptyOrSpaces(filter)) {
      setSearchedChat(data.filter(x => x?.firstName?.toLowerCase().includes(filter.toLowerCase()) || 
                                       x?.lastName?.toLowerCase().includes(filter.toLowerCase()) ||
                                       x?.subscriptionName.toLowerCase().includes(filter.toLowerCase())));
    } else {
      setSearchedChat(data);
    }
  }

  const changeSearch = (event) => {
    if (event?.target?.value) {
      updateSearchedChat(event.target.value);
      setSearch(event.target.value);
    } else {
      updateSearchedChat("");
      setSearch("");
    }
  }

  const isEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
  }

  const openChat = (chatId) => {
    if (data && Array.isArray(data) && chatId) {
      for (let el of data) {
        if (el.id === chatId) {
          setCurrentChatId(el.id);
          setCurrentChatOwner(el.userId);
        }
      }
    } else {
      setCurrentChatId(null);
      setCurrentChatOwner(null);
    }
  }

  const setArchive = (value) => {
    if (value !== isArchive) {
      setIsArchive(value);
      openChat(null);
    }
  }

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("at");
    localStorage.removeItem("rt");
    navigate("/");
  }

  return (
    <Container>
      <Menu>
        <Toolbar />
        <Divider />
        <List>
          <ListItem style={{background: "#d6d6d6"}} key={1} disablePadding>
            <ListItemButton style={{padding: "8px 16px"}} onClick={() => navigate("/chat")}>
              <ListItemText primary={"Čet"} />
            </ListItemButton>
          </ListItem>
          <ListItem key={2} disablePadding>
            <ListItemButton style={{padding: "8px 16px"}} onClick={() => navigate("/accounts")}>
              <ListItemText primary={"Nalozi"} />
            </ListItemButton>
          </ListItem>
          <ListItem key={3} disablePadding>
            <ListItemButton style={{padding: "8px 16px"}} onClick={() => navigate("/documents")}>
              <ListItemText primary={"Sadržaj"} />
            </ListItemButton>
          </ListItem>
          <ListItem key={4} disablePadding>
            <ListItemButton style={{padding: "8px 16px"}} onClick={() => navigate("/packages")}>
              <ListItemText primary={"Paketi"} />
            </ListItemButton>
          </ListItem>
          <ListItem key={5} divider>
          </ListItem>
          <ListItem key={6} disablePadding divider>
            <ListItemButton style={{padding: "8px 16px"}} onClick={() => logout("")}>
              <ListItemText primary={"Odjavi me"} />
            </ListItemButton>
          </ListItem>
        </List>
      </Menu>
      <Wrapper>
        <Toolbar style={{background: "#1976d2", color: "#ffffff", boxShadow: "rgba(0, 0, 0, 0.2) 1px 2px 4px 0px, rgba(0, 0, 0, 0.14) 3px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px"}}>
          <Typography variant="h6" noWrap component="div">
            Čet
          </Typography>
        </Toolbar>
      <ChatWrapper>
          { (!currentChatId || !isMobile) && <Chats>
            <ChatTabs>
              <ChatTabArchive isSelect={isArchive} onClick={() => setArchive(true)}>Arhiva</ChatTabArchive>
              <ChatTabActive isSelect={!isArchive} onClick={() => setArchive(false)}>Aktivno</ChatTabActive>
            </ChatTabs>
            <SearchDiv>
              <SearchInput type="text" id="searchInput" placeholder="Pretraži čet" value={search} onChange={changeSearch}/>
            </SearchDiv>
            <ChatBox chatId={currentChatId} searchedChat={searchedChat} chatType={isArchive ? "archive" : "active"} openChat={(curr) => openChat(curr)} setData={setData} />
          </Chats> }
          { currentChatId && <MessagesBox isArchive={isArchive} chatId={currentChatId} chatOwner={currentChatOwner} openChat={(curr) => openChat(curr)} />
          }
          
        </ChatWrapper>
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  background: #ffffff;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 500px) {
    flex-direction: row;
  }
`;

const Menu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  @media screen and (min-width: 500px) {
    width: 287px;
  }
`;

const Wrapper= styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 80vh;
`;

const ChatWrapper= styled.div`
  padding: 24px 10px 10px 24px;
  display: flex;
  height: 100%;
`;

const Chats = styled.div`
  width: 100%;
  height: 100%;
  background: #D9D9D9;
  overflow-y: auto;
  padding: 0.2rem;
  @media screen and (min-width: 991px) {
    width: 300px;
  }
`;

const SearchDiv= styled.div`
  padding-bottom: 10px;
`;

const SearchInput= styled.input`
  width: 97%;
  height: 25px;
  border-radius: 5px;
  border: 1px solid;
  padding: 3px;
`;

const ChatTabs= styled.div`
  width: 100%;
  display: flex;
  margin: 1px 0 10px 0;
  font-size: small;
`;

const chatTabStyle = css`
  width: 50%;
  padding: 5px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 2px;
  cursor: pointer;
`;

const ChatTabActive = styled.div`
  ${chatTabStyle};
  text-align: right;
  background: ${props => props.isSelect ? "#ffffff" : "#aba9a9"};
`;

const ChatTabArchive = styled.div`
  ${chatTabStyle};
  text-align: left;
  background: ${props => props.isSelect ? "#ffffff" : "#aba9a9"};
`;

export default ChatPage