import { useQuery } from "react-query";
import QueryKeys from "./queryKeys";
import { apiService } from "../../utils/api";

const { GET_PACKAGES } = QueryKeys;

async function getPackages() {
  const response = await apiService("/subscriptions", {
    method: "GET",
    credentials: "include",
  });
  return response.json();
}

export default function useGetPackages(onSuccess, onError) {
  return useQuery(GET_PACKAGES, getPackages, {
    onSuccess,
    onError,
    staleTime: 60 * 60 * 24, // 24h
  });
}
