import React, { useState, useEffect, useRef, useMemo } from "react";

import { FOLDER, LISTA } from "../../utils/constants";
import useGetFileSystem from "../../hooks/fetchers/useGetFileSystem";

import MagnifyIcon from "./MagnifyIcon";
import SearchResults from "./SearchResults";

import { Container, Input } from "./styles";

function SearchBar() {
  const { data: fileSystem, isLoading, isFetching } = useGetFileSystem();
  const [width, setWidth] = useState(0);
  const [term, setTerm] = useState("");
  const [input, setInput] = useState(null);
  const inputRef = useRef(null);

  const filterDocuments = () => {
    const documents = [];

    for (const key in fileSystem) {
      const obj = fileSystem[key];
      obj.hashPath = key;
      documents.push(obj);
    }

    const filteredDocs = documents.filter(
      (doc) =>
        doc.documentType === LISTA ||
        (doc.documentType === FOLDER &&
          doc.name.toLowerCase().includes(term.toLowerCase()))
    );

    return filteredDocs;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const paths = useMemo(() => filterDocuments(), [isLoading, isFetching, term]);

  useEffect(() => {
    if (inputRef.current) {
      setWidth(getComputedStyle(inputRef.current));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Input
      placeholder="Pretraga"
      value={input}
      onChange={(e) => setInput(e.target.value)}
      ref={inputRef}
      style={{ marginLeft: "1rem" }}
    >
      <MagnifyIcon
        fill="#545B61"
        style={{
          position: "absolute",
          pointerEvents: "none",
          left: 9,
          marginTop: 5,
        }}
        size={15}
      />
      <input
        placeholder="Pretraga"
        value={term}
        onChange={(event) => setTerm(event.target.value)}
      />
      {term.length > 0 ? (
        <Container style={{ width }}>
          <SearchResults
            style={{ width }}
            term={term}
            isDraggable={false}
            data={paths}
            closeResult={() => setTerm("")}
          />
        </Container>
      ) : (
        ""
      )}
    </Input>
  );
}

export default SearchBar;
