import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) =>
  createStyles({
    wrapForm : {
        display: "flex",
        justifyContent: "center",
        width: "95%",
        paddingTop: "25px",
        // margin: `${theme.spacing(0)} auto`
    },
    wrapText  : {
        width: "100%"
    },
    button: {
        //margin: theme.spacing(1),
    },
  })
);


export const TextInput = ({callback}) => {
    const classes = useStyles();
    const [message, setMessage] = useState();

    const sendMessage = () => {
        if (message && message !== "") {
            callback(message);
            setMessage("");
        }
    }

    const onChangeMessage = (event) => {
        if (event?.target?.value) {
            setMessage(event.target.value);
        } else {
            setMessage("");
        }
    }
    const keyPress = (e) => {
        if(e.keyCode === 13){
           sendMessage();
        }
     }
    return (
        <div className={classes.wrapForm}>
            <TextField
                id="standard-text"
                label="Poruka"
                className={classes.wrapText}
                onChange={onChangeMessage}
                value={message}
                onKeyDown={event => keyPress(event)}
                //margin="normal"
            />
            <Button variant="contained" color="primary" className={classes.button} onClick={sendMessage}>
                <SendIcon />
            </Button>
        </div>
    )
}



