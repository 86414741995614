import { useQuery } from "react-query";
import QueryKeys from "./queryKeys";
import { apiService } from "../../utils/api";

const { GET_MESSAGES } = QueryKeys;

async function getMessages(chatId) {
  const response = await apiService(`/chats/${chatId}`, {
    method: "GET",
    credentials: "include",
  });
  return response.json();
}

export default function useGetMessages(chatId, onSuccess, onError) {
  return useQuery([GET_MESSAGES, chatId], () => getMessages(chatId), {
    onSuccess,
    onError,
    select: (data) => data.result,
  });
}
