import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useResetPassword } from "../fetchers/useResetPassword";

export function useResetPasswordForm() {
  const navigate = useNavigate();
  const { mutate, isLoading } = useResetPassword();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Neispravna E-pošta")
        .required("E-pošta je obavezna"),
    }),
    onSubmit: (values) => {
      mutate(values, {
        onSuccess: async (data) => {
          if (data) {
            const res = await data.json()
            if (res?.error?.code === 404) {
              alert("Ne posoji korisnik sa zadatom E-poštom");
            } else {
              alert("Uspešno resetovanje");
              navigate("/");
            }
          }
        },
      });
    },
  });

  return [formik, isLoading];
}
