import { useQuery } from "react-query";
import QueryKeys from "./queryKeys";
import { apiService } from "../../utils/api";

const { GET_CHATS } = QueryKeys;

async function getChats(chatType) {
  let apiUrl = "/chats"
  if (chatType === "archive") {
    apiUrl = `${apiUrl}?ar=1`
  }
  const response = await apiService(apiUrl, {
    method: "GET",
    credentials: "include",
  });
  return response.json();
}

export default function useGetChats(chatType, onSuccess, onError) {
  return useQuery([GET_CHATS, chatType], () => getChats(chatType), {
    onSuccess,
    onError,
    select: (data) => data.result,
  });
}
