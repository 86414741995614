import { generatedummyFileSystem } from "../utils/mockFileSystem";

import { ADD_ENTRY, DELETE_ENTRY } from "../utils/constants";
import { DeleteEntry, AddEntry } from "../utils/fileSystem";

const INIT_FILE_SYSTEM = generatedummyFileSystem();

const initState = {
  fileSystem: INIT_FILE_SYSTEM,
};

export default (data = initState, action) => {
  switch (action.type) {
    case ADD_ENTRY: {
      const newEntry = action.payload;
      return AddEntry(data, newEntry);
    }
    case DELETE_ENTRY: {
      return DeleteEntry(data, action.payload);
    }
    default:
      return data;
  }
};
