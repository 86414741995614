import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../components/Sidebar";

import "../styles/userModal.css";
import AddEditUserForm from "../components/AddEditUserForm";

function AccountsAddPage() {
  return (
    <>
      <Sidebar title="Nalozi">
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Toolbar />
          <AddEditUserForm />
        </Box>
      </Sidebar>
    </>
  );
}

export default AccountsAddPage;
