import { useQuery } from "react-query";
import QueryKeys from "./queryKeys";
import { apiService } from "../../utils/api";

const { GET_FILE_SYSTEM } = QueryKeys;

async function getFileSystem() {
  const response = await apiService("/documents", {
    method: "GET",
  });
  return response.json();
}

export default function useGetFileSystem(onSuccess, onError) {
  return useQuery(GET_FILE_SYSTEM, getFileSystem, {
    onSuccess,
    onError,
    select: (data) => data.result,
  });
}
