import { useMutation, useQueryClient } from 'react-query';
import { apiService } from '../../utils/api';
import Keys from "./queryKeys";

const { GET_CHATS } = Keys;

async function sendMessage(message) {
  return apiService("/chats", {
    method: "POST",
    body: JSON.stringify(message),
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function useSendMessage() {
  const queryClient = useQueryClient();
  return useMutation(sendMessage, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_CHATS, "active");
      queryClient.invalidateQueries(GET_CHATS, "archive");
    }
  });
}