import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const PrivateRoute = () => {
  const isAuth = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      return true;
    }
    return false;
  }
  return ( isAuth()
    ? <Outlet />
    : <Navigate to="/" />
  )
}