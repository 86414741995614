import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import CircularProgress from "@mui/material/CircularProgress";
import Sidebar from "../components/Sidebar";
import useGetPackages from "../hooks/fetchers/useGetPackages";
import PackagesTable from "../components/PackagesTable";

import "../styles/userModal.css";

function PackagesPage() {
  const { data, isLoading, isError } = useGetPackages();

  return (
    <>
      <Sidebar title="Paketi">
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Toolbar />
          {isLoading || isError ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <PackagesTable
              data={data && data.result && !isError ? data.result : []}
            />
          )}
        </Box>
      </Sidebar>
    </>
  );
}

export default PackagesPage;
