import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Keys from "./queryKeys";
import { FILE, FOLDER, LINK, LISTA } from "../../utils/constants";
import md5 from "md5";
import { apiService } from "../../utils/api";
import { decodeURL } from "../../utils";

const { GET_FILE_SYSTEM } = Keys;

const getDocType = (type, parentPath) => {
  switch (type) {
    case FILE:
      return "file";
    case FOLDER:
      return parentPath === "/documents" ? "folder" : "subfolder";
    case LINK:
      return "link";
    case LISTA:
      return "content_list";
    default:
      return null;
  }
};

const search = (fileSystem, entry) => {
  let no = 0;

  if (fileSystem[entry.parentID]) {
    fileSystem[entry.parentID].childrenIdPath.forEach((elementId) => {
      if (
        fileSystem[elementId].name.includes(entry.name) &&
        fileSystem[elementId].type === entry.type
      ) {
        console.log(elementId);
        no++;
      }
    });
  }

  return no;
};

function createDocument({ data, newEntry }) {
  const body = {
    name: newEntry.name,
    type: getDocType(newEntry.type, newEntry.parentPath),
    link: newEntry.type === LINK ? newEntry.link : null,
    path: decodeURL(newEntry.parentPath + "/" + newEntry.name),
    parent: data[newEntry.parentID].id,
    parentPath: decodeURL(newEntry.parentPath),
    subscriptionIds: newEntry.package,
    subscriptionName: null,
    // isLocked: newEntry.isLocked, // OSTAVITI, MOZDA CE SE KORISTITI
  };

  return apiService("/documents", {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default function useCreateDocument() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(createDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_FILE_SYSTEM);
      navigate(0);
    },
  });
}
