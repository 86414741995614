import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { composeWithDevTools } from "redux-devtools-extension";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AccountsContextProvider } from "./context/accountsContext";
import mainReducer from "./reducers/fileSystemReducer";

import LoginPage from "./containers/LoginPage";
import ChatPage from "./containers/ChatPage";
import AccountsPage from "./containers/AccountsPage";
import DocumentsPage from "./containers/DocumentsPage";
import AccountsAddPage from "./containers/AccountsAddPage";
import PackageAddPage from "./containers/PackageAddPage";
import Grid from "./components/Grid";

import { PrivateRoute } from "./routes/privateRoute";
import { AuthRoute } from "./routes/authRoute";
import ResetPasswordPage from "./containers/ResetPasswordPage";
import { useEffect } from "react";
import PackagesPage from "./containers/PackagesPage";
import { onMessageListener } from "./firebase";
import Keys from "./hooks/fetchers/queryKeys";

const { GET_CHATS, GET_MESSAGES } = Keys;

const store = configureStore({ reducer: mainReducer }, composeWithDevTools());

const queryClient = new QueryClient();

let interval;

const App = () => {

  // onMessageListener().then(payload => {
  //   queryClient.invalidateQueries([GET_CHATS, "active"]);
  //   queryClient.invalidateQueries([GET_CHATS, "archive"]);
  //   queryClient.invalidateQueries([GET_MESSAGES, payload?.data?.chat]);
  //   alert("Stigla je nova poruka. Pogledajte je u odeljku Čet.");
  // }).catch(err => console.log('failed: ', err));


  useEffect(() => {
    const tokenTime = localStorage.getItem("tokenTime");
    if (!tokenTime) {
      const newTokenTime = Date.now();
      localStorage.setItem("tokenTime", newTokenTime);
      setRefreshTime();
    } else {
      const parsedTime = parseInt(tokenTime, 10);
      const twentyMinutes = 1200;
      const currentTime = Date.now();
      if (parsedTime && parsedTime + twentyMinutes < currentTime) {
        setRefreshTime((currentTime - (parsedTime + twentyMinutes)) * 1000);
      } else {
        refreshToken();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const refreshToken = () => {
    const currUser = JSON.parse(localStorage.getItem("user"));
    if (!currUser || !currUser.id) return;
    clearInterval(interval);
    const body = {};
    const rt = localStorage.getItem("rt");
    if (rt) {
      body.refreshToken = rt;
    }
    fetch(process.env.REACT_APP_API_BASE_URL + "/auth/admin-refresh-token", {
      method: "POST",
      body: JSON.stringify(body),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const resultJSON = await res.json();
      if (resultJSON?.result?.access_token) {
        localStorage.setItem("at", resultJSON.result.access_token);
        setRefreshTime();
      }
    });
  };

  const setRefreshTime = (milliseconds) => {
    const newTokenTime = Date.now();
    localStorage.setItem("tokenTime", newTokenTime);
    const twentyMinutesInMilliseconds = 1200000;
    interval = setInterval(
      refreshToken,
      milliseconds ? milliseconds : twentyMinutesInMilliseconds
    );
  };

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AccountsContextProvider>
          <Router>
            <Routes>
              <Route path="*" component={Grid} />
              <Route exact path="/" element={<AuthRoute />}>
                <Route path="/" element={<LoginPage />} />
              </Route>
              <Route exact path="/reset-password" element={<AuthRoute />}>
                <Route path="/reset-password" element={<ResetPasswordPage />} />
              </Route>
              <Route exact path="/chat" element={<PrivateRoute />}>
                <Route path="/chat" element={<ChatPage />} />
              </Route>
              <Route exact path="/accounts" element={<PrivateRoute />}>
                <Route exact path="/accounts" element={<AccountsPage />} />
              </Route>
              <Route exact path="/accounts/add" element={<PrivateRoute />}>
                <Route
                  exact
                  path="/accounts/add"
                  element={<AccountsAddPage />}
                />
              </Route>
              <Route exact path="/documents" element={<PrivateRoute />}>
                <Route path="/documents" element={<DocumentsPage />} />
              </Route>
              <Route path="/documents/*" element={<DocumentsPage />} />
              <Route exact path="/packages" element={<PrivateRoute />}>
                <Route exact path="/packages" element={<PackagesPage />} />
              </Route>
              <Route exact path="/packages/add" element={<PrivateRoute />}>
                <Route
                  exact
                  path="/packages/add"
                  element={<PackageAddPage />}
                />
              </Route>
            </Routes>
          </Router>
        </AccountsContextProvider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
