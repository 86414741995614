import { useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import AccountsContext from "../../context/accountsContext";
import useCreatePackage from "../fetchers/useCreatePackage";
import useUpdatePackage from "../fetchers/useUpdatePackage";

function setEditValues(formik, selectedItem) {
  formik.setFieldValue("name", selectedItem.name);
  formik.setFieldValue("description", selectedItem.description);
}

export default function useAddEditPackageForm() {
  const [state] = useContext(AccountsContext);
  const { editMode, selectedItem } = state;
  const { mutate: mutateCreatePackage, isLoading: isCreateLoading } =
    useCreatePackage();
  const { mutate: mutateUpdatePackage, isLoading: isUpdateLoading } =
    useUpdatePackage();

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Obavezno polje"),
      description: Yup.string().required("Obavezno polje"),
    }),
    onSubmit: async (values) => {
      if (editMode) {
        values.id = selectedItem.id;
        await mutateUpdatePackage(values);
      } else {
        await mutateCreatePackage(values);
      }
    },
  });

  useEffect(() => {
    if (editMode) {
      setEditValues(formik, selectedItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [formik, isCreateLoading, isUpdateLoading];
}
