import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Keys from "./queryKeys";
import { apiService } from "../../utils/api";

const { GET_ACCOUNTS } = Keys;

function createAccount(data) {
  const body = {
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    password: data.password,
    subscriptionExpiryDate:
      data.package === "none"
        ? null
        : new Date(data.dateOfExpiration).getTime(),
    subscriptionId: data.package === "none" ? null : data.package,
    roleName: data.typeOfAccount,
  };

  return apiService("/accounts", {
    method: "POST",
    body: JSON.stringify(body),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default function useCreateAccount() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(createAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_ACCOUNTS);
      navigate("/accounts");
      navigate(0);
    },
  });
}
