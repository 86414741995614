import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import { useResetPasswordForm } from "../hooks/forms/useResetPasswordForm";

import "../styles/login.css";

const ResetPasswordPage = () => {
  const [formik, isLoading] = useResetPasswordForm();

  return (
    <div className="rootRegister">
      <form onSubmit={formik.handleSubmit} className="rootRegister__buttonBox">
        <h2>Resetujte lozinku</h2>
        <div className="login-label-wrapper space-top">
          <label>E-pošta</label>
        </div>
        <TextField
          label="Epošta"
          variant="outlined"
          value={formik.values.email}
          className="input space-top"
          type="text"
          name="email"
          id="email"
          error={formik.touched.email && formik.errors.email}
          helperText={formik.touched.email && formik.errors.email ? formik.errors.email : null}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <div className="login-label-wrapper space-top">
          <LoadingButton loading={isLoading} type="submit" className="login-btn" variant="contained">
            Resetujte lozinku
          </LoadingButton>
        </div>
      </form>
    </div>
  );
}

export default ResetPasswordPage