import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Keys from "./queryKeys";
import { FILE, FOLDER, LINK, LISTA } from "../../utils/constants";
import { apiService } from "../../utils/api";

const { GET_FILE_SYSTEM } = Keys;

const getDocType = (type, parentPath) => {
  switch (type) {
    case FILE:
      return "file";
    case FOLDER:
      return parentPath === "/documents" ? "folder" : "subfolder";
    case LINK:
      return "link";
    case LISTA:
      return "content_list";
    default:
      return null;
  }
};

function updateDocument({ data, newEntry }) {
  delete newEntry.file;
  newEntry.type = getDocType(newEntry.type, data.parentPath);
  newEntry.subscriptionIds = newEntry.package ?? [];

  if (newEntry.package.length === 1 && newEntry.package[0] === "none") {
    newEntry.subscriptionIds = null;
  }

  return apiService("/documents", {
    method: "PUT",
    body: JSON.stringify(newEntry),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default function useUpdateDocument() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(updateDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_FILE_SYSTEM);
      navigate(0);
    },
  });
}
