import { useContext } from "react";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import useAddEditPackageForm from "../hooks/forms/useAddEditPackageForm";
import AccountsContext from "../context/accountsContext";

import "../styles/userForm.css";

function AddEditPackageForm() {
  const [state] = useContext(AccountsContext);
  const { editMode } = state;
  const [formik, isCreateLoading, isUpdateLoading] = useAddEditPackageForm();

  return (
    <form onSubmit={formik.handleSubmit} className="addUserForm">
      <Typography sx={{ marginBottom: "1rem" }} variant="h4" component="h2">
        {editMode ? "Izmeni Paket" : "Kreiraj Paket"}
      </Typography>
      <TextField
        value={formik.values.name}
        label="Naziv"
        variant="outlined"
        className="input addUserInput"
        type="text"
        name="name"
        id="name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.name && formik.errors.name}
        helperText={
          formik.touched.name && formik.errors.name ? formik.errors.name : null
        }
      />
      <TextField
        value={formik.values.description}
        label="Opis"
        variant="outlined"
        className="input addUserInput"
        type="text"
        name="description"
        id="description"
        onChange={formik.handleChange}
        onBlur={formik.lastName}
        error={formik.touched.description && formik.errors.description}
        helperText={
          formik.touched.description && formik.errors.description
            ? formik.errors.description
            : null
        }
      />
      <LoadingButton
        loading={isCreateLoading || isUpdateLoading}
        type="submit"
        variant="contained"
        size="large"
      >
        {editMode ? "IZMENI" : "KREIRAJ"}
      </LoadingButton>
    </form>
  );
}

export default AddEditPackageForm;
