import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

import useLockDocument from "../../hooks/fetchers/useLockDocument";
import useGetPackages from "../../hooks/fetchers/useGetPackages";
import withModal from "../DocModal";

import "../../styles/lockModal.css";

function FileInfo({ entryId }) {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const { mutate: lockDocument } = useLockDocument();
  const { data: packages, isLoading, isFetching } = useGetPackages();

  if (isLoading || isFetching) {
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      <CircularProgress />
    </Box>;
  }

  function onLock() {
    if (selectedPackage) {
      lockDocument({
        id: entryId,
        subscriptionName: selectedPackage,
      });
    }
  }

  return (
    <Box className="lock-modal">
      <FormControl className="lock-modal-select">
        <InputLabel id="package-select-label">Paket</InputLabel>
        <Select
          labelId="package-select-label"
          value={selectedPackage}
          label="Paket"
          onChange={(e) => setSelectedPackage(e.target.value)}
          className="lock-modal-select"
        >
          {packages?.result?.map((packageItem) => (
            <MenuItem key={packageItem.id} value={packageItem.name}>
              {packageItem.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        className="lock-modal-button"
        variant="contained"
        onClick={onLock}
      >
        Lock
      </Button>
    </Box>
  );
}

export default withModal(FileInfo)({
  style: {
    position: "absolute",
    zIndex: 2,
  },
});
