import { useMutation } from 'react-query';
import { apiService } from '../../utils/api';

async function loginUser(user) {
  return apiService("/auth/admin-login", {
    method: "POST",
    body: JSON.stringify(user),
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    },
  });
}

async function refreshToken() {
  return apiService("/auth/refresh-token", {
    method: "POST",
    body: JSON.stringify({refreshToken: ""}),
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function useLoginUser() {
  return useMutation(loginUser);
}

export function useRefreshToken() {
  return useMutation(refreshToken);
}