import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import useGetMessages from "../../hooks/fetchers/useGetMessages";
import { Box, CircularProgress, Paper } from "@material-ui/core";
import { MessageLeft, MessageRight } from "./Message";
import { TextInput } from "./TextInput";
import { useQueryClient } from "react-query";
import Keys from "../../hooks/fetchers/queryKeys";
import { useSendMessage } from "../../hooks/fetchers/useSendMessage";
// import { useReadMessage } from "../../hooks/fetchers/useReadMessage";
import styled from "styled-components";
import CLOSE_IMG from "../../icons/close_icon.svg";

const { GET_MESSAGES } = Keys;

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: "calc(100% - 300px)",
      height: "88vh",
      minWidth: "350px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative"
    },
    paper2: {
      width: "80vw",
      maxWidth: "500px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative"
    },
    container: {
      width: "100%",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    messagesBody: {
      width: "calc( 100% - 20px )",
      margin: 10,
      overflowY: "scroll",
      height: "calc( 100% - 80px )"
    }
  })
);


export const MessagesBox = ({isArchive, chatId, chatOwner, openChat}) => {
  let { data, isLoading } = useGetMessages(chatId);
  const [ messages, setMessages ] = useState([]);
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { mutate: mutateSendMessage } = useSendMessage();
  // const { mutate } = useReadMessage();
  let messagesEnd;

  useEffect(() => {
    queryClient.invalidateQueries([GET_MESSAGES, chatId]);
  }, [chatId]);

  useEffect(() => {
    if (data && messages && data.length >= messages.length) {
      setMessages(data);
    }
    // scrollToBottom();
    // if (data?.length > 0 && data[data.length-1] && data[data.length-1].receiver === "admin" && !data[data.length-1].isRead) {
    //   mutate({messageId: data[data.length-1]?.id})
    // }
  }, [data]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEnd?.scrollIntoView();
  }

  const sendMessage = (text) => {
    const currUser = JSON.parse(localStorage.getItem("user"));
    if (text && chatId) {
      const receiver = chatOwner;
      const message = {
        "text": text,
        "isSenderAdmin": true,
        "sender": currUser?.id ? currUser.id : "",
        "receiver": receiver,
        "createdOn": Date.now(),
      }

      setMessages([...messages, message]);
      scrollToBottom();
      mutateSendMessage(message);
    }
  }


  return (
    isLoading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            chatId && 
            messages && Array.isArray(messages) && messages.length > 0 &&
              <Paper className={classes.paper} zdepth={2}>
                <CloseButton onClick={() => openChat(null)}>
                  <img src={CLOSE_IMG} alt="Close" width={15} />
                </CloseButton>
            <Paper id="style-1" className={classes.messagesBody}>
              { messages.map(x => (
                x.receiver === "admin" ?
                <MessageLeft
                  key={x.id}
                  message={x.text}
                  timestamp={x.createdOn}
                  displayName=""
                  avatarDisp={true}
              /> :
              <MessageRight
                key={x.id}
                message={x.text}
                timestamp={x.createdOn}
                photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
                displayName="まさりぶ"
                avatarDisp={true}
              />
              ))}
              <div ref={(el) => { messagesEnd = el; }} />
            </Paper>
            {!isArchive && <TextInput callback={sendMessage} />}
          </Paper>
  ));
}

const CloseButton = styled.button`
  border: none;
  cursor: pointer;
  background: #ffffff;
`;