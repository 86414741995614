import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Keys from "./queryKeys";
import { apiService } from "../../utils/api";

const { GET_FILE_SYSTEM } = Keys;

function deleteDocument({ fileSystem, entryID }) {
  const entry = fileSystem[entryID];

  return apiService(`/documents/${entry.id}`, {
    method: "DELETE",
  });
}

export default function useDeleteDocument() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(deleteDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_FILE_SYSTEM);
      navigate(0);
    },
  });
}
