import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { addDeviceToken } from './hooks/fetchers/useAddDeviceToken';
var firebaseConfig = {
  apiKey: 'AIzaSyCRHImrqUvzOGgl010vgdjVzVWnU8SFlCs',
  authDomain: 'cmn-app-37.firebaseapp.com',
  projectId: 'cmn-app-37',
  messagingSenderId: "875032854295",
  appId: '1:1032054946949:web:a7d9891aeccdfbf0a9fdec',
};

// const firebaseApp = initializeApp(firebaseConfig);
// const messaging = getMessaging(firebaseApp);

// export const getDeviceToken = (userId) => {
//   return getToken(messaging, {vapidKey: 'BB-PWXIPGa9cfFzJZfBcA6X0BPA1mcHJHbKiiUgVz-ujgUfiUb8TTOk7DeHElSpfKDH0OvrEaYbrOEKMXDhZkhk'}).then((currentToken) => {
//     if (currentToken) {
//       console.log('current token for client: ', currentToken);
//       addDeviceToken({userId, token: currentToken});
//       // Track the token -> client mapping, by sending to backend server
//       // show on the UI that permission is secured
//     } else {
//       console.log('No registration token available. Request permission to generate one.');
//       // shows on the UI that permission is required 
//     }
//   }).catch((err) => {
//     console.log('An error occurred while retrieving token. ', err);
//     // catch error while creating client token
//   });
// }

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
// });