import { useContext } from "react";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useAddEditUserForm from "../hooks/forms/useAddEditUserForm";
import AccountsContext from "../context/accountsContext";

import "../styles/userForm.css";
import useGetPackages from "../hooks/fetchers/useGetPackages";

function AddEditUserForm() {
  const [state] = useContext(AccountsContext);
  const { editMode } = state;
  const { data, isLoading } = useGetPackages();
  const [formik, isCreateLoading, isUpdateLoading] = useAddEditUserForm();

  return isLoading ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <form onSubmit={formik.handleSubmit} className="addUserForm">
      <Typography sx={{ marginBottom: "1rem" }} variant="h4" component="h2">
        {editMode ? "Izmeni Nalog" : "Kreiraj Nalog"}
      </Typography>
      <TextField
        value={formik.values.firstName}
        label="Ime"
        variant="outlined"
        className="input addUserInput"
        type="text"
        name="firstName"
        id="firstName"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.firstName && formik.errors.firstName}
        helperText={
          formik.touched.firstName && formik.errors.firstName
            ? formik.errors.firstName
            : null
        }
      />
      <TextField
        value={formik.values.lastName}
        label="Prezime"
        variant="outlined"
        className="input addUserInput"
        type="text"
        name="lastName"
        id="lastName"
        onChange={formik.handleChange}
        onBlur={formik.lastName}
        error={formik.touched.lastName && formik.errors.lastName}
        helperText={
          formik.touched.lastName && formik.errors.lastName
            ? formik.errors.lastName
            : null
        }
      />
      <TextField
        value={formik.values.email}
        label="Email"
        variant="outlined"
        className="input addUserInput"
        type="email"
        name="email"
        id="email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.email && formik.errors.email}
        helperText={
          formik.touched.email && formik.errors.email
            ? formik.errors.email
            : null
        }
      />
      <FormControl variant="outlined" margin="none">
        <InputLabel id="type-of-account">Tip Naloga</InputLabel>
        <Select
          name="typeOfAccount"
          id="type-of-account"
          label="Tip Naloga"
          className="input addUserInput addUserForm--noPadding"
          value={formik.values.typeOfAccount}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.typeOfAccount && formik.errors.typeOfAccount}
          helperText={
            formik.touched.typeOfAccount && formik.errors.typeOfAccount
              ? formik.errors.typeOfAccount
              : null
          }
        >
          <MenuItem value={"admin"}>Administrator</MenuItem>
          <MenuItem value={"korisnik"}>Korisnik</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" margin="none">
        <InputLabel id="package">Paket</InputLabel>
        <Select
          name="package"
          id="package"
          label="Paket"
          className="input addUserInput addUserForm--noPadding"
          value={formik.values.package}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.package && formik.errors.package}
          helperText={
            formik.touched.package && formik.errors.package
              ? formik.errors.package
              : null
          }
        >
          <MenuItem value="none" key="none">
            Bez Paketa
          </MenuItem>
          {data.result.length > 0 &&
            data.result.map((packageItem) => (
              <MenuItem value={packageItem.id}>{packageItem.name}</MenuItem>
            ))}
        </Select>
      </FormControl>
      <TextField
        value={formik.values.password}
        label="Lozinka"
        variant="outlined"
        className="input addUserInput"
        type="password"
        name="password"
        id="password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.password && formik.errors.password}
        helperText={
          formik.touched.password && formik.errors.password
            ? formik.errors.password
            : null
        }
      />
      <TextField
        value={formik.values.repeatPassword}
        label="Ponovite Lozinku"
        variant="outlined"
        className="input addUserInput"
        type="password"
        name="repeatPassword"
        id="repeatPassword"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.repeatPassword && formik.errors.repeatPassword}
        helperText={
          formik.touched.repeatPassword && formik.errors.repeatPassword
            ? formik.errors.repeatPassword
            : null
        }
      />
      {formik.values.package === "none" ? null : (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            className="input addUserInput"
            label="Datum Isteka Paketa"
            format="dd/MM/yyyy"
            value={formik.values.dateOfExpiration}
            onChange={(newValue) => {
              formik.setFieldValue("dateOfExpiration", newValue);
            }}
            disablePast
          />
        </LocalizationProvider>
      )}
      <LoadingButton
        loading={isCreateLoading || isUpdateLoading}
        type="submit"
        variant="contained"
        size="large"
      >
        {editMode ? "IZMENI" : "KREIRAJ"}
      </LoadingButton>
    </form>
  );
}

export default AddEditUserForm;
