import { useContext, useEffect } from "react";
import { parse } from "date-fns";
import { useFormik } from "formik";
import * as Yup from "yup";
import AccountsContext from "../../context/accountsContext";
import useCreateAccount from "../fetchers/useCreateAccount";
import useUpdateAccount from "../fetchers/useUpdateAccount";

function setEditValues(formik, selectedItem) {
  formik.setFieldValue("firstName", selectedItem.firstName);
  formik.setFieldValue("lastName", selectedItem.lastName);
  formik.setFieldValue("email", selectedItem.email);
  formik.setFieldValue(
    "typeOfAccount",
    selectedItem.typeOfAccount.toLowerCase()
  );
  formik.setFieldValue(
    "package",
    selectedItem.subscriptionId ? selectedItem.subscriptionId : "none"
  );
  formik.setFieldValue(
    "dateOfExpiration",
    selectedItem.dateOfExpiration
      ? Date.now()
      : getDateOfExpiration(selectedItem.dateOfExpiration)
  );
}

function getDateOfExpiration(date) {
  return parse(date, "dd/MM/yyyy", new Date());
}

export default function useAddEditUserForm() {
  const [state] = useContext(AccountsContext);
  const { editMode, selectedItem } = state;
  const { mutate: mutateCreateAccount, isLoading: isCreateLoading } =
    useCreateAccount();
  const { mutate: mutateUpdateAccount, isLoading: isUpdateLoading } =
    useUpdateAccount();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      typeOfAccount: "",
      package: "",
      dateOfExpiration: Date.now(),
      password: "",
      repeatPassword: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Obavezno polje"),
      lastName: Yup.string().required("Obavezno polje"),
      email: Yup.string().email("Pogrešan format").required("Obavezno polje"),
      typeOfAccount: Yup.string().required("Obavezno polje"),
      package: Yup.string().required("Obavezno polje"),
      dateOfExpiration: Yup.string().required("Obavezno polje"),
      password: editMode
        ? null
        : Yup.string()
            .required("Obavezno polje")
            .min(8, "Minimalno 8 karaktera"),
      repeatPassword: editMode
        ? null
        : Yup.string()
            .required("Obavezno polje")
            .oneOf([Yup.ref("password"), null], "Sifre nisu iste"),
    }),
    onSubmit: async (values) => {
      if (editMode) {
        values.id = selectedItem.id;
        await mutateUpdateAccount(values);
      } else {
        await mutateCreateAccount(values);
      }
    },
  });

  useEffect(() => {
    if (editMode) {
      setEditValues(formik, selectedItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [formik, isCreateLoading, isUpdateLoading];
}
