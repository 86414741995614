import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { FILE } from "../../utils/constants";
import FileIcon from "../../icons/file.png";
import FolderIcon from "../../icons/folder.png";

import { Result, NoResult, Img } from "./styles";

function SearchResults({ data }) {
  const navigate = useNavigate();

  const onClickSearchResult = (path) => {
    const encodedPath = path.replace(/ /g, "%20");
    navigate(encodedPath);
    navigate(0);
  };

  return (
    <Fragment>
      {data.length > 0 ? (
        data.map((arr) => (
          <Result key={arr.path} onClick={() => onClickSearchResult(arr.path)}>
            <div>
              <Img src={arr.documentType === FILE ? FileIcon : FolderIcon} />
              {arr.name}
            </div>
          </Result>
        ))
      ) : (
        <NoResult>Nema Rezultata</NoResult>
      )}
    </Fragment>
  );
}

export default SearchResults;
