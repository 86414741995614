const dummyFileSystem = {
  de9f4298c8202522f635ec9baf7a511e: {
    type: "__folder__",
    name: "root",
    path: "/documents",
    size: 0,
    date: "2019-04-07",
    creatorName: "admin",
    parentPath: null,
    parentID: null,
    children: [
      "8a8d21ca7edfa51bf91d7db2256f9dbe",
      "f56597cc53d6aadd111e645a17891fe9",
      "a55cfa9e1bf87138edd25c4b1553104d",
      "5f2b4d35489a8617e574060b19b7cad9",
      "ab7e413a3798155e37a9361140522e39",
      "891debd77d0bc40d30ff7d7e6c628e9f",
    ],
  },
  "8a8d21ca7edfa51bf91d7db2256f9dbe": {
    type: "__folder__",
    name: "apps",
    creatorName: "Shubham Singh",
    size: 223,
    date: "2019-04-29",
    parentID: "de9f4298c8202522f635ec9baf7a511e",
    parentPath: "/documents",
    path: "/documents/apps",
    children: [],
  },
  f56597cc53d6aadd111e645a17891fe9: {
    type: "__folder__",
    name: "pictures",
    creatorName: "Shubham Singh",
    size: 23,
    date: "2019-04-29",
    parentID: "de9f4298c8202522f635ec9baf7a511e",
    parentPath: "/documents",
    path: "/documents/pictures",
    children: [],
  },
  a55cfa9e1bf87138edd25c4b1553104d: {
    type: "__folder__",
    name: "videos",
    creatorName: "Shubham Singh",
    size: 0,
    date: "2019-04-29",
    parentID: "de9f4298c8202522f635ec9baf7a511e",
    parentPath: "/documents",
    path: "/documents/videos",
    children: [],
  },
  "5f2b4d35489a8617e574060b19b7cad9": {
    type: "__folder__",
    name: "docs",
    creatorName: "Shubham Singh",
    size: 233,
    date: "2019-04-29",
    parentID: "de9f4298c8202522f635ec9baf7a511e",
    parentPath: "/documents",
    path: "/documents/docs",
    children: [
      "2d03459789f153918dfc0be413fe9987",
      "8f7c5959dbb088c0aef8b145dbdf6e43",
      "579c51eec02e43b4dfad314e05365fe4",
    ],
  },
  ab7e413a3798155e37a9361140522e39: {
    type: "__file__",
    name: "a.pdf",
    creatorName: "Shubham Singh",
    size: 234,
    date: "2019-04-29",
    parentID: "1382b6993e9f270cb1c29833be3f5750",
    parentPath: "/documents",
    path: "/documents/a.pdf",
  },
  "891debd77d0bc40d30ff7d7e6c628e9f": {
    type: "__file__",
    name: "b.jpg",
    creatorName: "Shubham Singh",
    size: 234,
    date: "2019-04-29",
    parentID: "1382b6993e9f270cb1c29833be3f5750",
    parentPath: "/documents",
    path: "/documents/b.jpg",
  },
  "2d03459789f153918dfc0be413fe9987": {
    type: "__folder__",
    name: "work",
    creatorName: "Shubham Singh",
    size: 200,
    date: "2019-04-29",
    parentID: "5f2b4d35489a8617e574060b19b7cad9",
    parentPath: "/documents/docs",
    path: "/documents/docs/work",
    children: [
      "b42eff45517edc2e543b3d2750bd08c3",
      "00ce12a7746322ce403e17992674f81b",
    ],
  },
  "8f7c5959dbb088c0aef8b145dbdf6e43": {
    type: "__file__",
    name: "c.pdf",
    creatorName: "Shubham Singh",
    size: 200,
    date: "2019-04-29",
    parentID: "5f2b4d35489a8617e574060b19b7cad9",
    parentPath: "/documents/docs",
    path: "/documents/docs/c.pdf",
  },
  "579c51eec02e43b4dfad314e05365fe4": {
    type: "__file__",
    name: "d.docx",
    creatorName: "Shubham Singh",
    size: 235,
    date: "2019-04-29",
    parentID: "5f2b4d35489a8617e574060b19b7cad9",
    parentPath: "/documents/docs",
    path: "/documents/docs/d.docx",
  },
  b42eff45517edc2e543b3d2750bd08c3: {
    type: "__file__",
    name: "e.pdf",
    creatorName: "Shubham Singh",
    size: 0,
    date: "2019-04-29",
    parentID: "2d03459789f153918dfc0be413fe9987",
    parentPath: "/documents/docs/work",
    path: "/documents/docs/work/e.pdf",
  },
  "00ce12a7746322ce403e17992674f81b": {
    type: "__file__",
    name: "f.ts",
    creatorName: "Shubham Singh",
    size: 235,
    date: "2019-04-29",
    parentID: "2d03459789f153918dfc0be413fe9987",
    parentPath: "/documents/docs/work",
    path: "/documents/docs/work/f.ts",
  },
};

export const generatedummyFileSystem = () => {
  let fileSystem = dummyFileSystem;

  if ("fileSystem" in localStorage) {
    fileSystem = JSON.parse(localStorage.getItem("fileSystem"));
  } else {
    localStorage.setItem("fileSystem", JSON.stringify(dummyFileSystem));
  }

  return fileSystem;
};

export default generatedummyFileSystem;
