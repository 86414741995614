import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Container, Path } from "./styles";
import GoBack from "./GoBack";
import { decodeURL } from "../../utils";

const showPath = (path) => {
  const pathArr = path.split("/").filter((p) => p);
  const len = pathArr.length;
  const arr = [<span key={0}>{` root `}</span>];

  pathArr.map((p, _) => {
    _ === len - 1
      ? arr.push(
          <span className="currentPath" key={_ + 1}>
            / {p}
          </span>
        )
      : arr.push(<span key={_ + 1}>{` / ${p} `}</span>);
  });
  return arr;
};

const Navigation = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const goBack = (path) => {
    let newPath = path.split("/");
    newPath.splice(newPath.length - 1, 1);

    navigate(newPath.join("/"));
    navigate(0);
  };

  return (
    <Container>
      <div
        style={{ marginTop: -2, cursor: "pointer" }}
        onClick={() => {
          return location.pathname === "/" ? null : goBack(location.pathname);
        }}
      >
        <GoBack fill={location.pathname === "/" ? "#acb9c3" : "#545B61"} />
      </div>
      <Path>{showPath(decodeURL(location.pathname))}</Path>
    </Container>
  );
};

export default Navigation;
