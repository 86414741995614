import { useMutation } from 'react-query';
import { apiService } from '../../utils/api';

async function resetPassword(resetForm) {
  return apiService("/auth/reset-password", {
    method: "POST",
    body: JSON.stringify(resetForm),
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function useResetPassword() {
  return useMutation(resetPassword);
}