import { useReducer, createContext } from "react";

import { reducer, initialState } from "./accountsReducer";

const AccountsContext = createContext(null);

export function AccountsContextProvider({ children }) {
  return (
    <AccountsContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </AccountsContext.Provider>
  );
}

export default AccountsContext;
