export const apiService = (url, options, count) => {
  const at = localStorage.getItem("at");
  let headers = {};
  if (!options) {
    options = {};
  }
  if (options?.headers) {
    headers = options?.headers;
  }
  if (at) {
    headers.Authorization = `Bearer ${at}`;
  }
  options.headers = headers;
  return fetch(process.env.REACT_APP_API_BASE_URL + url, options).then(
    async (res) => {
      const response = await res.clone().json();
      if (response?.error) {
        if (response.error.code === 403 || response.error.code === 401) {
          localStorage.removeItem("user");
          localStorage.removeItem("at");
          localStorage.removeItem("rt");
          window.location.href = process.env.REACT_APP_SITE_URL;
        } else if (response.error.code === 504) {
          const body = {};
          const rt = localStorage.getItem("rt");
          if (rt) {
            body.refreshToken = rt;
          }
          headers["Content-Type"] = "application/json";
          fetch(
            process.env.REACT_APP_API_BASE_URL + "/auth/admin-refresh-token",
            {
              method: "POST",
              body: JSON.stringify(body),
              credentials: "include",
              headers: headers,
            }
          ).then(async result => {
            const resultJSON = await result.json();
            if (resultJSON?.result?.access_token) {
              localStorage.setItem("at", resultJSON?.result.access_token);
            }
            if (!count || count !== 1) {
              await apiService(url, options, 1);
            }
          });
        }
      }
      return res;
    }
  );
};
