import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { FILE, FOLDER, LINK, LISTA } from "../../utils/constants";
import { truncateString } from "../../utils";

import FileIcon from "../../icons/file.png";
import FolderIcon from "../../icons/folder.png";

import { Container, Logo, Img, Name } from "./styles";
import Menu from "../DocMenu";
import FileInfo from "../FileInfo";
import FileMoveCopy from "../FileMoveCopy";

const DOC_NAME_MAX_CHARS = 20;

function GridIcon(props) {
  const navigate = useNavigate();
  const nodeRef = useRef(null);
  const [visible, toggleVisible] = useState(false);
  const [isLoading, toggleLoading] = useState(false);
  const [isMoveCopyModalOpen, toggleMoveCopyModal] = useState(false);
  const [moveCopyStatus, setMoveCopyStatus] = useState(null);
  const [showInfo, toggleShowInfo] = useState(false);
  const [style, setStyle] = useState({ right: 0, left: 0 });
  const [prevStyle, setPrevStyle] = useState({ right: 0, left: 0 });

  const entries = useMemo(() => {
    let ext = props.entry.name.split(".").filter((el) => el);
    ext = ext.length >= 2 ? ext[ext.length - 1] : "";
    return ext;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("contextmenu", _handleContextMenu);
    document.addEventListener("click", _handleMouseLeave);

    return () => {
      document.removeEventListener("contextmenu", _handleContextMenu);
      document.removeEventListener("click", _handleMouseLeave);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function _handleContextMenu(event) {
    event.preventDefault();

    const path = event.composedPath();

    const wasOutside = !path.includes(nodeRef.current) || false;

    if (wasOutside) {
      toggleVisible(false);
      setStyle({ right: 0, left: 0 });
      setPrevStyle({ right: 0, left: 0 });
      return;
    }

    const clickX = event.clientX;
    const clickY = event.clientY;
    const screenW = window.innerWidth;
    const screenH = window.innerHeight;
    const rootW = nodeRef.current.offsetWidth;
    const rootH = nodeRef.current.offsetHeight;

    const right = screenW - clickX > rootW;
    const left = !right;
    const top = screenH - clickY > rootH;
    const bottom = !top;

    const tempStyle = {
      left: 0,
      top: 0,
    };

    if (right) {
      tempStyle.left = `${clickX + 5}px`;
    }

    if (left) {
      tempStyle.left = `${clickX - rootW - 5}px`;
    }

    if (top) {
      tempStyle.top = `${clickY + 5}px`;
    }

    if (bottom) {
      tempStyle.top = `${clickY - rootH - 5}px`;
    }

    const prevStyle = {
      top: tempStyle.top,
      left: tempStyle.left,
    };

    toggleVisible(true);
    setStyle(tempStyle);
    setPrevStyle(prevStyle);
  }

  function _handleMouseLeave(event) {
    const wasOutside = !(event.target.contains === nodeRef.current);

    if (wasOutside) {
      toggleVisible(false);
      setStyle({ right: 0, left: 0 });
    }
  }

  function handleDelete() {
    props.deleteFn();
  }

  function enterFolder() {
    if (
      props.entry.documentType === FOLDER ||
      props.entry.documentType === LISTA
    ) {
      navigateAndRefresh(props.entry.path);
    } else if (props.entry.documentType === LINK) {
      window.open(props.entry.link, "_blank");
    }
  }

  function navigateAndRefresh(url) {
    navigate(url);
    navigate(0);
  }

  function downloadFile(url) {
    toggleLoading(true);
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.type = blob.type;
        link.setAttribute(
          "download",
          props.entry.name + "." + blob.type.split("/")[1]
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        toggleLoading(false);
        window.URL.revokeObjectURL(url);
      })
      .catch(() => toggleLoading(false));
  }

  const menuContent = useMemo(() => {
    const menu = [
      {
        info: "Obriši",
        style: { color: "red" },
        onClick: () => {
          handleDelete();
        },
      },
      {
        info: "Premesti",
        onClick: () => {
          toggleMoveCopyModal(true);
          setMoveCopyStatus("move");
        },
      },
      {
        info: "Kopiraj",
        onClick: () => {
          toggleMoveCopyModal(true);
          setMoveCopyStatus("copy");
        },
      },
      {
        info: "Izmeni",
        onClick: () => {
          props.setSelectedDoc(props.entry);
          props.toggleEdit(true);
          props.openAddEditModal();
        },
      },
    ];

    if (props.entry.documentType === FILE) {
      menu.push({
        info: "Preuzmi",
        onClick: () => {
          downloadFile(props.entry.link);
        },
      });
    }

    // NE BRISATI, MOZDA CE SE KORISTITI
    // if (props.entry.documentType === FOLDER) {
    //   menu.push({
    //     info: props.entry.subscriptionId === null ? "Zaključaj" : "Outključaj",
    //     onClick: () => {
    //       if (props.entry.subscriptionId === null) {
    //         toggleShowInfo(true);
    //         toggleVisible(false);
    //       } else {
    //         unlockDocument(props.entry.id);
    //       }
    //     },
    //   });
    // }

    return menu.reverse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.entry.documentType]);

  return isLoading ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Container ref={nodeRef}>
      <Logo onClick={() => enterFolder()}>
        <Img src={props.entry.documentType === FILE ? FileIcon : FolderIcon} />
        {props.entry.documentType === FILE ? <span>{`.${entries}`}</span> : ""}
      </Logo>
      <Name>{truncateString(props.entry.name, DOC_NAME_MAX_CHARS)}</Name>
      {visible && <Menu style={style} content={menuContent} />}
      {showInfo ? ( // Lock/Unlock Modal
        <FileInfo
          title="Lock Package"
          style={prevStyle}
          closeFn={() => toggleShowInfo(false)}
          entryId={props.entry.id}
        />
      ) : (
        ""
      )}
      {isMoveCopyModalOpen ? (
        <FileMoveCopy
          closeFn={() => toggleMoveCopyModal(false)}
          mode={moveCopyStatus}
          entry={props.entry}
        />
      ) : (
        ""
      )}
    </Container>
  );
}

export default GridIcon;
