import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Keys from "./queryKeys";
import { apiService } from "../../utils/api";

const { GET_FILE_SYSTEM } = Keys;

function createFile({ data, newEntry }) {
  const formData = new FormData();

  const options = {
    name: newEntry.name,
    parent: data[newEntry.parentID].id,
    path: newEntry.parentPath + "/" + newEntry.name,
    parentPath: newEntry.parentPath,
    fileExtension: newEntry.file.name.split(".").pop(),
  };

  formData.append("file", newEntry.file);
  formData.append("document", JSON.stringify(options));

  return apiService("/documents/upload", {
    method: "POST",
    body: formData,
  });
}

export default function useCreateFile() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(createFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_FILE_SYSTEM);
      navigate(0);
    },
  });
}
