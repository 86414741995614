import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLoginUser } from "../fetchers/useLoginUser";
// import { getDeviceToken } from "../../firebase";

export function useLoginForm() {
  const navigate = useNavigate();
  const { mutate, isLoading } = useLoginUser();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Neispravna E-pošta")
        .required("E-pošta je obavezna"),
      password: Yup.string()
        .required("Lozinka je obavezna")
        .min(6, "Lozinka mora imati minimalno 6 karaktera"),
    }),
    onSubmit: (values) => {
      mutate(values, {
        onSuccess: async (data) => {
          if (data) {
            const response = await data.json();
            if (response?.result?.user) {
              // getDeviceToken(response.result.user.id);
              localStorage.setItem(
                "user",
                JSON.stringify(response?.result?.user)
              );
              if (response.result.accessToken && response.result.refreshToken) {
                localStorage.setItem("at", response.result.accessToken);
                localStorage.setItem("rt", response.result.refreshToken);
              }
              // let interval;
              // try {
              //   interval = setInterval(mutateRefreshToken, 1000 * 60)
              // } catch (err) {
              //   if (interval) {
              //     clearInterval(interval);
              //   }
              // }
              navigate("/accounts");
            } else {
              if (response?.error?.length > 0) {
                alert("Neispravan unos");
              } else if (response?.error) {
                alert("Neispravna Epošta ili Lozinka")
              } else {
                alert("Neispravan unos");
              }
            }
          }
        },
      });
    },
  });

  return [formik, isLoading];
}
