import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../components/Sidebar";

import "../styles/userModal.css";
import AddEditPackageForm from "../components/AddEditPackageForm";

function PackageAddPage() {
  return (
    <>
      <Sidebar title="Paketi">
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Toolbar />
          <AddEditPackageForm />
        </Box>
      </Sidebar>
    </>
  );
}

export default PackageAddPage;
