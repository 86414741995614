import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Keys from "./queryKeys";
import { apiService } from "../../utils/api";

const { GET_FILE_SYSTEM } = Keys;

function lockDocument({ id, subscriptionName }) {
  const options = {
    documentIds: [id],
    subscriptionName,
  };

  return apiService("/documents/lock", {
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default function useLockDocument() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(lockDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_FILE_SYSTEM);
      navigate(0);
    },
  });
}
