import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import CircularProgress from "@mui/material/CircularProgress";
import Sidebar from "../components/Sidebar";
import AccountsTable from "../components/AccountsTable";
import useGetAccounts from "../hooks/fetchers/useGetAccounts";

import "../styles/userModal.css";

function AccountsPage() {
  const { data, isLoading, isError } = useGetAccounts();

  return (
    <>
      <Sidebar title="Nalozi">
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Toolbar />
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <AccountsTable data={data && !isError ? data : []} />
          )}
        </Box>
      </Sidebar>
    </>
  );
}

export default AccountsPage;
